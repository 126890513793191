<script>
import { h, createCommentVNode } from 'vue';

export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    return () => props.content ? h('style', { innerHTML: (props.content || '').replaceAll('<style>', '').replaceAll('</style>', '') }) : createCommentVNode('');
  },
};
</script>
